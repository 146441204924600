import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ProjectTile from './ProjectTile'
import { motion } from 'framer-motion'
// import { useState, useEffect } from 'react'


// Template for showing all project tiles on a page
const ProjectsRollTemplate = (props) => {
  
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
		<motion.div layout className='grid is-col-min-12 is-gap-3'>
			{posts && posts.map(({ node: post }, index) => (
				<ProjectTile post={post} key={post.id} index={index} />
			) 
		)}
		</motion.div>
	)
}

ProjectsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

// query all projects
export default function ProjectsRoll() {
  return (
		<StaticQuery
			query={graphql`
				query ProjectsRollQuery {
					allMarkdownRemark(
						sort: { order: ASC, fields: [frontmatter___order] } #[frontmatter___date]
						filter: {
							frontmatter: { 
								templateKey: { eq: "project-post" }, 
								hidden: { ne: true } }
						}
					) {
						edges {
							node {
								excerpt(pruneLength: 100)
								id
								fields {
									slug
								}
								frontmatter {
									title
									subtitle
									# description
									templateKey
									date(formatString: "MMMM DD, YYYY")
									featured
									hidden
									image {
										childImageSharp {
											gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED) # CONSTRAINED, FIXED, FULL_WIDTH
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <ProjectsRollTemplate data={data} count={count} />}
		/>
	)
}
